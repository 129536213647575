import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { graphql } from 'gatsby';
import { convertToVW } from 'shared/utils';
import SEO from 'components/SEO/SEO';
import { black, white } from 'shared/colors';
import { FlavorObject } from 'types/shared';
import { BREAKPOINTS } from 'shared/constants';
import ProductCard from 'components/ProductCard/ProductCard';
import ShopFlavorTitle from 'components/ShopFlavorTitle/ShopFlavorTitle';
const bg = require('images/herov2.jpg');

interface Props {
  data: {
    contentfulShopPage: {
      flavors: FlavorObject[];
    };
  };
  location: {
    pathname: string;
  };
}

const ShopPage = ({ data, location: { pathname } }: Props) => {
  const multipleProducts: FlavorObject[] = [];
  const singleProducts: FlavorObject[] = [];

  data.contentfulShopPage.flavors.forEach((flavor) => {
    if (flavor.products.length > 1) {
      multipleProducts.push(flavor);
    } else {
      singleProducts.push(flavor);
    }
  });

  return (
    <>
      <SEO title="Shop" pathname={pathname} />
      <div className={css(styles.container)}>
        {multipleProducts &&
          multipleProducts.map(({ id, name, description, products, slug }) => {
            return (
              <Fragment key={id}>
                <ShopFlavorTitle name={name} description={description} />
                <div className={css(styles.flexContainer)}>
                  {products.map(
                    (
                      {
                        id,
                        imageAltText,
                        productVariants,
                        image: { fluid },
                        name: productName,
                        threedmodel,
                        useThreedModelAsThumb,
                        useVideoOnHoverAsThumbnail,
                        backgroundVideo,
                      },
                      index
                    ) => {
                      return (
                        <ProductCard
                          key={id}
                          id={id}
                          imageAltText={imageAltText}
                          productVariants={productVariants}
                          fluid={fluid}
                          slug={slug}
                          productName={productName}
                          index={index}
                          isSingle={false}
                          isPackagingChoices={false}
                          threedmodel={threedmodel}
                          useThreedModelAsThumb={useThreedModelAsThumb}
                          useVideoOnHoverAsThumbnail={
                            useVideoOnHoverAsThumbnail
                          }
                          backgroundVideo={backgroundVideo}
                        />
                      );
                    }
                  )}
                </div>
              </Fragment>
            );
          })}

        <div className={css(styles.gridContainer)}>
          {singleProducts &&
            singleProducts.map(({ id, name, description, products, slug }) => {
              return (
                <div key={id} className={css(styles.gridItem)}>
                  <ShopFlavorTitle
                    name={name}
                    description={description}
                    isSingle
                  />
                  {products.map(
                    (
                      {
                        id,
                        image: { fluid },
                        imageAltText,
                        productVariants,
                        name: productName,
                        threedmodel,
                        useThreedModelAsThumb,
                        useVideoOnHoverAsThumbnail,
                        backgroundVideo,
                      },
                      index
                    ) => {
                      return (
                        <ProductCard
                          key={id}
                          id={id}
                          imageAltText={imageAltText}
                          productVariants={productVariants}
                          fluid={fluid}
                          slug={slug}
                          productName={productName}
                          index={index}
                          isSingle
                          isPackagingChoices={false}
                          threedmodel={threedmodel}
                          useThreedModelAsThumb={useThreedModelAsThumb}
                          useVideoOnHoverAsThumbnail={
                            useVideoOnHoverAsThumbnail
                          }
                          backgroundVideo={backgroundVideo}
                        />
                      );
                    }
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className={css(styles.productCtaContainer)}>
        <img
          id="herov2"
          className={css(styles.productCta)}
          src={bg}
          alt="Reviewed by Accessible 360"
        />
        <div className={css(styles.textWrapper)}>
          <p className={css(styles.subheader)}>HYPE WILLIAMS X SLIM AARONS</p>
          <h1 className={css(styles.title)}>THE GOOD LIFE, REDEFINED</h1>
          <div className={css(styles.buttonWrapperHardcode)}>
            <a href="/editorial/thegoodliferedefined">
              <button className={css(styles.buttonHardcode)}>
                <p className={css(styles.buttonTextHardcode)}>
                  view behind the scenes
                </p>
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopPage;

const styles = StyleSheet.create({
  container: {
    backgroundColor: black,
    color: white,
    paddingTop: '120px',
    [BREAKPOINTS.TABLET]: {},
    [BREAKPOINTS.MOBILE]: {
      paddingTop: '90px',
    },
  },
  flexContainer: {
    display: 'flex',
    flex: 'auto',
    [BREAKPOINTS.TABLET]: {
      flexDirection: 'column',
      flex: '1',
    },
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: '1fr',
    [BREAKPOINTS.TABLET]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  productCta: {
    display: 'block',
    filter: 'brightness(0.5)',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  productCtaContainer: {
    position: 'relative',
    backgroundColor: black,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'calc(100vw * 9 / 16)',

    [BREAKPOINTS.TABLET]: {
      height: '100vw',
    },
  },
  textWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',

    [BREAKPOINTS.TABLET]: {
      width: 'calc(100vw - 80px)',
    },
  },
  subheader: {
    position: 'relative',
    fontSize: 12,
    fontFamily: 'Titling Gothic FB Wide',
    color: white,
    lineHeight: '12px',
    textTransform: 'uppercase',
    paddingBottom: '20px',
    [BREAKPOINTS.TABLET]: {
      fontSize: 8,
    },
  },
  title: {
    color: white,
    fontFamily: 'Titling Gothic FB Compressed Standard',
    textTransform: 'uppercase',
    fontSize: '70px',
    lineHeight: '70px',
    paddingBottom: '20px',
    [BREAKPOINTS.MOBILE]: {
      fontSize: 40,
      lineHeight: 1,
    },
  },
  buttonWrapperHardcode: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',

    [BREAKPOINTS.MOBILE]: {
      margin: '-1rem auto',
      transform: 'scale(0.75)',
    },
  },
  buttonHardcode: {
    color: black,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    alignSelf: 'center',
    height: convertToVW(123),
    width: '360px',
    maxWidth: '450px',
    maxHeight: '100px',
    minHeight: '70px',
    border: '1px solid white',
    cursor: 'pointer',
    backgroundColor: 'white',
    transition: 'all 0.3s ease-in-out',
    [BREAKPOINTS.FOOTER_BREAK_12]: {
      width: '450px',
    },
    [BREAKPOINTS.FOOTER_BREAK_95]: {
      width: '270px',
    },
  },
  buttonTextHardcode: {
    fontSize: 12,
    color: black,
    fontFamily: 'Titling Gothic FB Wide',
    [BREAKPOINTS.TABLET]: {},
  },
});

export const query = graphql`
  query ShopPageQuery {
    contentfulShopPage {
      flavors {
        id
        slug
        name
        description
        products {
          id
          name
          imageAltText
          image {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          productVariants {
            id
            name
            potency
            potencyValue
            price
          }
          threedmodel
          useThreedModelAsThumb
          useVideoOnHoverAsThumbnail
          backgroundVideo {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
